import styled from "styled-components"

export const Subheading = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #222128;
  margin-top: 16px;
  max-width: 720px;
`
