import { graphql } from "gatsby"

import "../common/colors.css"
import Layout from "../components/Layout"
import { FaqPage } from "../components/FaqPages/FaqPage"
import { FaqPageProps } from "../components/FaqPages/FaqPage/types"

interface PageProps {
  data: FaqPageProps
}

export default function DynamicPage({ data }: PageProps): JSX.Element {
  return (
    <Layout>
      <FaqPage data={data} />
    </Layout>
  )
}

export const query = graphql`
  query FaqPageQuery {
    popular_topics: allPrismicFaqRedesign(
      filter: { data: { popular: { eq: true } } }
    ) {
      edges {
        node {
          data {
            question {
              text
            }
            categories {
              category
            }
          }
          uid
        }
      }
    }
    categories_info: prismicFaqCategoryInfo {
      data {
        category_info {
          subheading {
            html
          }
          title {
            text
          }
          category_image {
            gatsbyImageData
          }
          landing_image {
            gatsbyImageData
          }
        }
        faq_subheading {
          html
        }
        faq_title {
          text
        }
      }
    }
    faqs: allPrismicFaqRedesign {
      edges {
        node {
          uid
          data {
            categories {
              category
            }
            answer {
              html
            }
            question {
              text
            }
          }
        }
      }
    }
  }
`
