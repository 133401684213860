import SEO from "../../SEO"
import { Navigation } from "../../ui/Navigation"
import { FaqPageBody } from "./styled"
import { FaqPageProps } from "./types"
import { FrequentlyAskedQuestions } from "./sections"
import { FaqTopics } from "../../shared/FaqTopics"
import { organizationSchemaMarkup } from "../../../common/OrganizationSeoSchema"

export const FaqBody = ({ data }: { data: FaqPageProps }): JSX.Element => (
  <FaqPageBody>
    {data.categories_info.data && (
      <FrequentlyAskedQuestions
        categoriesInfo={data.categories_info.data}
        faqs={data.faqs.edges}
      />
    )}
    {data.popular_topics && (
      <FaqTopics title={"Popular Topics"} topics={data.popular_topics.edges} />
    )}
  </FaqPageBody>
)

export const FaqPage = ({ data }: { data: FaqPageProps }): JSX.Element => {
  return (
    <>
      <SEO
        title="FAQs | Cerebral"
        description="All the info on Cerebral, all in one place."
        url="faqs/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <FaqBody data={data} />
    </>
  )
}
