import { H1 } from "../../../../ui"
import { FaqCategoriesInfoType, FaqType } from "../../../shared/types"
import { FaqCategoryLinks } from "../../../shared/FaqCategoryLinks"
import { Container } from "./styled"
import { Subheading } from "../../../shared/styled"

export const FrequentlyAskedQuestions = ({
  categoriesInfo,
  faqs,
}: {
  categoriesInfo: FaqCategoriesInfoType
  faqs: FaqType[]
}) => {
  return (
    <Container>
      <H1 style={{ textAlign: "center" }}>{categoriesInfo.faq_title.text}</H1>
      <Subheading
        style={{ marginBottom: "80px" }}
        dangerouslySetInnerHTML={{ __html: categoriesInfo.faq_subheading.html }}
      />
      <FaqCategoryLinks categoriesInfo={categoriesInfo} faqs={faqs} />
    </Container>
  )
}
